/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 10:47:49
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-20 10:48:43
 */
import MapChart from './mapChart.vue'
export default MapChart
