/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:52:05
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-18 18:52:30
 */
import Region from './region.vue'
export default Region
